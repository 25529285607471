import React, { useEffect, useState } from 'react'
import '@aws-amplify/ui/dist/style.css';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import Upload from '../upload';
import logo from '../../img/logo_white.png';
import '../../css/App.css';


function App() {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <div className="App">
      <div className="container d-flex flex-column">
        <header className="App-header mb-4">
          <div className="logo mb-4">
            <a href="/">
              <img src={logo} className="App-logo" alt="WAIVIT" />
            </a>
          </div>
          <div className="welcome">
            Welcome to the EVP Commercial Comparable Sales Data portal
          </div>
        </header>
        <Upload user={user} />
      </div>
    </div>
  ) : (
      <AmplifyAuthenticator>
        <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp />
      </AmplifyAuthenticator>
  );
}

export default App;
