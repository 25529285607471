/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:d1800fa6-25af-4bff-9d4d-8d0ff2523cce",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_FCs8fkQz1",
    "aws_user_pools_web_client_id": "7kt8qgd7j6u8ki57q8mpv9fevk",
    "oauth": {},
    "aws_user_files_s3_bucket": "com-comp-data-raw-files155014-pdfupload",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "comCompDataApi",
            "endpoint": "https://1ofykrl9u8.execute-api.us-east-2.amazonaws.com/pdfupload",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
