import React, { Component } from 'react'
import axios from 'axios';
import { API } from 'aws-amplify'

import '../../css/Upload.css'
import Dropzone from '../dropzone';
import Progress from '../progress';
import SuccessfulUploadSvg from '../../img/check_circle_outline-24px.svg'

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullyUploaded: false
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullyUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullyUploaded: true, uploading: false });
    }
  }

  sendRequest(file) {
    // Request made to the backend api
    const user = this.props.user;
    const path = '/getUploadUrl';
    const token = user.getSignInUserSession().getIdToken().getJwtToken();
    const options = { // OPTIONAL
      headers: { Authorization: token },
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {  // OPTIONAL
        name: file.name,
        type: file.type
      },
    };

    return API
      .get('comCompDataApi', path, options)
      .then(response => {
        const formData = new FormData();
        Object.keys(response.data.fields).forEach(key => {
          formData.append(key, response.data.fields[key]);
        });

        // Actual file has to be appended last.
        formData.append("file", file);

        const onUploadProgress = progressEvent => {
          var percentage = (progressEvent.loaded / progressEvent.total) * 100
          let state = 'pending'
          if (percentage === 100.0) {
            state = 'done'
          }
          const uploadProgress =  {
            ...this.state.uploadProgress,
            [file.name]: { state, percentage },
          }
          this.setState({ uploadProgress })
        }

        const headers = {
          'Content-Type': file.type,
          'x-amz-meta-user-name': user.attributes.name,
          'x-amz-meta-user-email': user.attributes.email,
        }

        return axios.post(
          response.data.upload_url,
          formData,
          { headers, onUploadProgress }
        );
    });
  }

  renderActions() {
    if (this.state.successfullyUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ files: [], successfullyUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </button>
      );
    }
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullyUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src={SuccessfulUploadSvg}
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="Upload">
        <span className="Title">Please Select PDF Files to Upload</span>
        <div className="Content">
          <div>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || this.state.successfullyUploaded}
            />
            <div className="Actions">{this.renderActions()}</div>
          </div>
          <div className="Files">
            {this.state.files.map(file => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Upload
